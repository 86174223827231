import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Fade, Backdrop, DialogActions, Button } from '@material-ui/core';
import ProgressBar from '../ProgressBar';
import Loader from '../Loader';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles((theme) => ({
  modal: ({ type }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: `${
      type === "success" ?
      theme.palette.success.dark : 
        type === "error" ?
        theme.palette.error.dark : "#222222"
      }`
  }),
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    position: 'relative',
    width: '80%',
    minWidth: 320,
    '& .close': {
      position: 'absolute',
      right: 10,
      top: 10,
      cursor: 'pointer',
    },
    '& .modal-title': {
      fontSize: '18px',
      fontWeight: '600',
      letterSpacing: '0',
      lineHeight: '18px',
      marginBottom: '10px',
    },
    '& .modal-description': {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '15px',
      marginTop: 30,
      display: 'flex',
      alignItems: 'center',
      '& .modal-message': {
        marginLeft: '10px'
      }
    }
  },
}));


const Alert = ({ alert, setAlert }) => {

  const classes = useStyles({ type: alert.type });

  const handleClose = () => {
    if (alert.type !== 'progress' && alert.type !== 'loading')
      setAlert({ open: false });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={alert.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={alert.open}>
        {
          alert.type === 'loading' ? (
            <Loader />
          ) : (
            <div className={classes.paper}>
              {
                alert.type !== 'progress' && (
                  <div className="close">
                    <CloseIcon onClick={handleClose} />
                  </div>
                )
              }
              <div className="modal-title">{alert.title}</div>
              {alert.percent === null && (
                <div>Chargement... </div>
              )}
              {alert.type === 'progress' && alert.percent !== null && (
                <ProgressBar percent={alert.percent} />
              )}
              <div className="modal-description">
                <span>
                  <CloudDownloadIcon />
                </span>
                <span className="modal-message">
                  {alert.text}
                </span>
              </div>
              {
                alert.type === 'confirmation' && (
                  <DialogActions>
                    <Button onClick={() => {
                      alert.handleStop();
                      alert.handleVideoStream();
                      handleClose();
                    }} color="primary">
                      Non
                    </Button>
                    <Button onClick={() => {
                      alert.handleConfirm();
                      alert.handleVideoStream();
                      handleClose();
                    }} color="primary" autoFocus>
                      Oui
                    </Button>
                  </DialogActions>
                )
              }
            </div>
          )
        }
      </Fade>
    </Modal>
  )
}

export default Alert;