import React from 'react';
import BlockList from './BlockList'
import styles from './checklist.module.css';

function Checklist() {
  return (
    <div className={styles.checklistPage}>
      <div className={styles.checklistHeader}>
        <img className={styles.checklistIcon} src="liste-de-courses.svg" alt="" />
        <span>Checklist</span>
      </div>
      <BlockList />
    </div>
  )
}

export default Checklist;