import React, { Fragment } from 'react';
import Image from './Image'
import styles from './galery.module.css';

export default ({
  data,
}) => {

  const type = data.contentType.split('/')[0];

  return (
    <Fragment>
      <div className={styles.fullScreenSlide}>
        {
          (type === 'image') ?
            <Image
              thumb={data.thumbnailUrl || data.url}
              alt=""
              src={data.url}
              className={styles.fullScreenMedia}
            /> :
            <video className={styles.fullScreenMedia} src={data.url} controls />
        }
      </div>
    </Fragment>
  )
}