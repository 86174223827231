import React, { Fragment, useState, useRef, useContext } from 'react';
import hasMediaRecorder from 'utils/hasMediaRecorder';
import { AlertContext } from 'context/Alert';
import Video from './Video';
import TokBox from './TokBox';
import Preview from './Preview';
import styles from './camera.module.css';

export default (props) => {
  const {
    type,
    latestCapture,
    getBackCameraId,
    showZoom,
    cameraZoom,
    error,
    setError,
    toggleCameraId
  } = props;
  const [timer, setTimer] = useState(0);

  const videoRef = useRef(null);
  
  const { setAlert } = useContext(AlertContext)

  return (
    <Fragment>
      {
        !error ? (
          <Fragment>
            {
              hasMediaRecorder() ? (
                <Video
                  {...props}
                  error={error}
                  setError={setError}
                  timer={timer}
                  setTimer={setTimer}
                  getBackCameraId={getBackCameraId}
                  setAlert={setAlert}
                />
              )
              : (
                <TokBox
                  { ...props }
                  error={error}
                  setError={setError}
                  timer={timer}
                  setTimer={setTimer}
                  getBackCameraId={getBackCameraId}
                  setAlert={setAlert}
                />
              )
            }
            {
              showZoom && (
                <div className={styles.zoom}>
                  {
                    cameraZoom.toFixed(1).replace('.', ',')
                  }
                  <span>X</span>
                </div>
              )
            }
            <div className={styles.counter}>0:{timer} / 0:40 </div>
          </Fragment>
        ) : (
          <p className={styles.messageError}>{error}</p>
        )
      }
      <Preview
        type={type}
        videoRef={videoRef}
        latestCapture={latestCapture}
      />
      <button
        onClick={toggleCameraId}
        className={styles.toggle}
      >
        <img alt="" src="rotate.png" />
      </button>
    </Fragment>
  )
}