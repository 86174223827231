const firebaseAuthErrors = {
  'auth/user-not-found': 'Adresse e-mail ou mot de passe invalide',
  'auth/email-already-in-use': 'Cette adresse email est déjà utilisée',
  'auth/user-disabled': 'Cet utilisateur a été désactivé',
  'auth/operation-not-allowed':'Opération non autorisée',
  'auth/invalid-email':'Courriel invalide',
  'auth/wrong-password': 'Adresse e-mail ou mot de passe invalide',
  'auth/network-error':'Problème lors de la tentative de connexion au serveur',
  'auth/weak-password':'Mot de passe très faible ou invalide',
  'auth/missing-email':'Vous devez renseigner un e-mail',
  'auth/internal-error':'Erreur interne',
  'auth/too-many-requests':'De nombreuses demandes ont déjà été envoyées au serveur',
}; // list of firebase auth error codes to alternate error messages in french

export default (error) => {
  // console.log('error', error);
  return firebaseAuthErrors[error.code] || error.message;
}


