import React, { useState } from 'react';
import checkList from '../utils/checkListArray';

export const CheckListContext = React.createContext();

export const CheckListProvider = ({ children }) => {
  const [list, setList] = useState(checkList);
  const [distribution, setDistribution] = useState(null);
  const [technicalInspectionDate, setTechnicalInspectionDate] = useState(null);
  const editCheckList = (title, value) => {
    const newList = list.map(obj =>
      obj.title === title ? { ...obj, todo: value === 'true' ? true : false } : obj
    );
    setList(newList);
  }
  const getToDoList = () => list.filter(elem => elem.todo);
  const clearToDoList = () => {
    const clearedList = list.map(elem => ({
      ...elem,
      todo: false,
    }));
    setList(clearedList)
    setDistribution(null);
    setTechnicalInspectionDate('');
  };
  return (
    <CheckListContext.Provider value={{
        list,
        editCheckList,
        getToDoList,
        clearToDoList,
        distribution,
        setDistribution,
        technicalInspectionDate,
        setTechnicalInspectionDate
    }}>
      {children}
    </CheckListContext.Provider>
  );
};