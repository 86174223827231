import axios from 'axios';
import uploadStream from 'services/uploadStream';
import config from 'config/config';

export default async (archiveId, setAlert, isConfirmed, setIsconfirmed, userId) => {
  try {
    if (isConfirmed === true) {
      setAlert({
        type: 'progress',
        title: 'Téléchargement de la vidéo',
        text: 'Le téléchargement de votre vidéo est en cours. Veuillez rester sur la page jusqu\'à ce qu’il soit complété',
        percent: null,
        open: true,
      });
      const result = await axios.get(`${config.tokBoxUrl}/tokbox/getArchive`, {
        params: {
          archiveId,
        }
      });
      uploadStream(true, result.data, 'video/mp4', userId, setAlert);
      setIsconfirmed(null);
      return result.data.url;
    } else if (isConfirmed === false) {
      const result = await axios.delete(`${config.tokBoxUrl}/tokbox/deleteArchive`, {
        params: {
          archiveId,
        }
      });
      setIsconfirmed(null);
      return result.data.url;
    }
  } catch (e) {
    console.error(e);
  }
}
