import React, { useRef, useState, useEffect, Fragment } from 'react';
import uploadStream from 'services/uploadStream';
import CheckIcon from '@material-ui/icons/Check';
import styles from './camera.module.css';
import Preview from './Preview';

const { mediaDevices } = navigator;

const constants = {
  SUPPORTS_IMAGE_CAPTURE: 'ImageCapture' in window,
  SUPPORTS_MEDIA_DEVICES: 'mediaDevices' in navigator,
}

export default ({
  userId,
  type,
  latestCapture,
  setCameraCapabilities,
  cameraCapabilities,
  showZoom,
  cameraZoom,
  trackRef,
  streamErrors,
  camera,
  error,
  setError,
  toggleCameraId
}) => {
  const [startPhoto, setStartPhoto] = useState(false);
  const [flash, setFlash] = useState('flash');
  const [photoCapabilities, setphotoCapabilities] = useState(null);

  const streamRef = useRef(null);
  const canvasRef = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    async function getStream() {
      setError('')
      return mediaDevices.getUserMedia(
        {
          video: {
            width: { min: 1024, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 720, max: 1080 },
            frameRate: { min: 15, ideal: 24 },
            ...(camera ? { deviceId: camera } : { facingMode: { exact: 'environment' } })
          },
        }
      ).then((stream) => {
        if(streamRef.current) {
          streamRef.current.muted = true;
          window.stream = stream;
          if ('srcObject' in streamRef.current) streamRef.current.srcObject = stream;
          else streamRef.current.src = window.URL.createObjectURL(stream)
          if (error) setError('')

          streamRef.current.onloadedmetadata = async () => {
            const track = stream.getVideoTracks()[0];
            trackRef.current = track;

            if (constants.SUPPORTS_IMAGE_CAPTURE) {
              const capture = new ImageCapture(track);
              const result = await capture.getPhotoCapabilities();
              setphotoCapabilities(result)
            }

            const capabilities = track.getCapabilities()
            setCameraCapabilities(capabilities)

            streamRef.current.play()
          }
        }
      })
      .catch((e) => {
        console.error(e)
        if (e.name === 'NotAllowedError') setError(streamErrors.notAuthorised);
        else if (e.constraint && e.constraint === 'facingMode') setError(streamErrors.notDetected);
        else if (e.message === 'Failed to allocate videosource') setError(streamErrors.reserved);
        else setError(streamErrors.notSupported);
      })
    }

    if (!error) getStream();

    return () => {
      if (streamRef && streamRef.current && streamRef.current.srcObject) {
        streamRef.current.srcObject.getTracks().map(t => t.stop()); // eslint-disable-line
      }
    }
  }, [type, error, userId, camera]) // eslint-disable-line

  const drawCanvas = () => {
    const video = streamRef.current;
    const context = canvasRef.current.getContext('2d')
    canvasRef.current.width = streamRef.current.videoWidth;
    canvasRef.current.height = streamRef.current.videoHeight;
    context.drawImage(
      video,
      0,
      0,
    );
  }
  
  const takePhoto = async () => {
    const hasTorch = cameraCapabilities.torch !== undefined
    if (flash === 'flash' && hasTorch) {
      trackRef.current.applyConstraints({
        advanced: [{ torch: true }]
      })
    }

    if (!constants.SUPPORTS_MEDIA_DEVICES) {
      return null;
    }

    if (flash === 'flash' && hasTorch) {
      setTimeout(() => {
        trackRef.current.applyConstraints({
          advanced: [{ torch: false }]
        })
      }, 700)
    }

    setTimeout(() => {
      if (flash === 'flash' && hasTorch) {
        trackRef.current.applyConstraints({
          advanced: [{ torch: true }]
        })
      }
      setStartPhoto(true);
      drawCanvas();
      canvasRef.current.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        if (imgRef.current) {
          imgRef.current.onload = () => {
            URL.revokeObjectURL(url);
          };
          imgRef.current.src = url;
          imgRef.current.classList.add("taken");
        }
        uploadStream(
          false,
          blob,
          'image/jpeg',
          userId
        );
      }, 'image/jpeg', 0.5);
      setTimeout(() => {
        setStartPhoto(false);
      }, 200);
    }, (flash === 'flash' && hasTorch) ? 850 : 0)

    if (flash === 'flash' && hasTorch) {
      setTimeout(() => {
        trackRef.current.applyConstraints({
          advanced: [{ torch: false }]
        })
      }, 1000)
    }
  }

  const toggleFlash = () => {
    if (flash === 'off') setFlash('flash')
    else setFlash('off')
  }

  return (
    <Fragment>
      {
        !error ? (
          <Fragment>
            <canvas ref={canvasRef} width="480" height="320" opacity="100%" />
            <video autoPlay ref={streamRef} muted playsInline className={styles.videoStream} />
            {startPhoto && (<div className={styles.startPhoto}></div>)}
            { photoCapabilities && photoCapabilities.fillLightMode && photoCapabilities.fillLightMode.length !== 0 &&
              <button className={styles.flash} onClick={toggleFlash}>
                <img alt="" src={flash === 'off' ? 'flash-off.png' : 'flash.png'} />
              </button>
            }
            {
              showZoom && (
                <div className={styles.zoom}>
                  {
                    cameraZoom.toFixed(1).replace('.', ',')
                  }
                  <span>X</span>
                </div>
              )
            }
          </Fragment>
        ): <p className={styles.messageError}>{error}</p>
      }
      <div className={styles.camMenu}>
        <Preview
          type={type}
          imgRef={imgRef}
          latestCapture={latestCapture}
        />
        {
          !error && (
            <Fragment>
              {startPhoto ? (
                <button disabled className={styles.snap}>
                  <CheckIcon className={styles.checkIcon} /><div>
                  </div>
                </button>
              ) : (
                  <button onClick={takePhoto} className={styles.snap}>
                    <span
                      style={{
                        backgroundColor: 'white',
                        width: '40px',
                        height: '40px',
                        borderRadius: '100%',
                      }}
                    />
                  </button>
                )
              }
            </Fragment>
          )
        }
          <button
            onClick={toggleCameraId}
            className={styles.toggle}
          >
            <img alt="" src="rotate.png" />
          </button>
      </div>
    </Fragment>
  )
}
