import React, { Fragment } from 'react';
import { useHistory } from "react-router-dom";
import styles from './camera.module.css';

export default ({
  latestCapture,
  type,
  imgRef,
  videoRef
}) => {
  const history = useHistory();

  const toGalery = () => history.push('/galerie');

  const captureType = () => {
    if (!latestCapture.contentType) {
      return type === 'photo' ? 'image' : 'video';
    }
    return latestCapture.contentType.split('/')[0] === 'image' ? 'image' : 'video';
  }

  return latestCapture && (
    <Fragment>
      {
        captureType() === 'image' && (latestCapture.thumbnailUrl || latestCapture.url)  ? (
          <img
            src={latestCapture.thumbnailUrl || latestCapture.url || '//:0'}
            alt=""
            className={styles.capture}
            onClick={toGalery}
            ref={imgRef}
          />
        ) : (
          <video
            autoPlay
            muted
            playsInline
            className={styles.capture}
            onClick={toGalery}
            src={latestCapture.url || '//:0'}
            ref={videoRef}
          />
        )
      }
    </Fragment>
  )
}

