import React, { useState } from 'react';
import Alert from 'components/Alert'

export const AlertContext = React.createContext();

export const AlertProvider = ({ children }) => {

  const [alert, setAlert] = useState({
    type: 'success',
    text: '',
    title: '',
    percent: 0,
    open: false,
  });
  
  return (
    <AlertContext.Provider value={{setAlert}}>
      <Alert setAlert={setAlert} alert={alert} />
      {children}
    </AlertContext.Provider>
  );
};