import React, { useContext } from 'react';
import { CheckListContext } from 'context/CheckList';
import styles from './blockList.module.css';

function BlockItems({ItemContent}) {
  const { editCheckList } = useContext(CheckListContext);

  const onChangeValue = (event) => {
    editCheckList(ItemContent.title, event.target.value);
  }

  return (
    <div className={styles.ElementTitle}>
      <div className={styles.itemTitle}>{ItemContent.title}</div>  
      <div className={styles.checkboxContent}>
        <div className={styles.checkboxElement}>
          <span>OK</span>
          <input
            type="checkbox"
            name="todo"
            value={false}
            checked={ItemContent.todo === false} 
            onChange={onChangeValue}
          />
        </div>
        <div className={styles.checkboxElement}>
          <span>F</span>
          <input
            type="checkbox"
            name="todo"
            value={true}
            checked={ItemContent.todo === true}
            onChange={onChangeValue}
          />
        </div>
      </div>
    </div>
  )
}

function BlockTitle({title}) {
  return (
    <div className={styles.blockTitleContent}>
      <div className={styles.blockTitle}>
        <img src={`${title}.svg`} alt="" />
        <h1>{title}</h1>
      </div>
      <div className={styles.tbody}>
        <span>Bon état</span>
        <span>À faire</span>
      </div>
    </div>
  )
}


function BlockList() {
  const {
    list,
    distribution,
    setDistribution,
    technicalInspectionDate,
    setTechnicalInspectionDate 
  } = useContext(CheckListContext);

  const displayBlockItems = (type) => {
    const ItemsOfBlock = list.filter(elm => elm.type === type)
    return ItemsOfBlock.map(item => <BlockItems ItemContent={item} />)
  } 

  const displayBlock = () => {
    const blockTypes = [...new Set(list.map((ele) => ele.type))]
    return blockTypes.map(type => (
      <div className={styles.blockTypesSection}>
        <BlockTitle title={type} />
        <div className={styles.blockTypesContent}>
          {displayBlockItems(type)}
        </div>
      </div>
    ))
  }

  return (
    <div className={styles.checklistContent}>
      {displayBlock()}
      <div className={styles.blockTypesSection}>
        <div  className={styles.form}>
          <div className={styles.inputGroup}>
            <label htmlFor="distribution">Distribution</label>
            <div className={styles.InputContent}>
              <input
                type="number"
                name="distribution"
                id="distribution"
                value={distribution}
                onChange={(e) => setDistribution(e.target.value)}
              />
              <span>Km</span>
            </div>
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="date">Date contrôle technique</label>
            <div className={styles.InputContent}>
              <input 
                type="date" 
                name="date" 
                id="date" 
                value={technicalInspectionDate} 
                onChange={(e) => setTechnicalInspectionDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlockList;