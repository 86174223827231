import styled from 'styled-components';

const LoaderWrapper = styled.div`
  .loader {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  /*
    Set the color of the icon
  */
  svg path,
  svg rect {
    fill: #091F41;
  }
`;
export default LoaderWrapper;
