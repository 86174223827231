import React, { useRef, useState, useEffect } from 'react';
import Hammer from 'react-hammerjs';
import Photo from './Photo';
import VideoContainer from './VideoContainer'
import styles from './camera.module.css';

export default (props) => {
  const [cameraZoom, setCameraZoom] = useState(1);
  const [lastScale, setLastScale] = useState(1);
  const [showZoom, setShowZoom] = useState(false);
  const [cameras, setCameras] = useState([]);
  const [camera, setCamera] = useState('initial');
  const [cameraCapabilities, setCameraCapabilities] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const getCameras = async () => {
      /*
        * This fake stream is just a little hack in order to get labels of enumerateDevices
        * The problem in some browsers if we don't grant access to camera before calling enumerateDevices
          we will have empty labels
        * Maybe this hack will not do the job in all devices, browsers, but at little it can do it in some of them :D
        * We will not attach it to them dom/ we will delete related tracks in next lines
      */
      const fakeStream = await navigator.mediaDevices.getUserMedia({video: {
        width: { min: 1024, ideal: 1280, max: 1920 },
        height: { min: 480, ideal: 720, max: 1080 },
      }});
      const devices = await navigator.mediaDevices.enumerateDevices();
      const backCameraId = getBackCameraId(devices);
      fakeStream.getTracks().map(t => t.stop());
      setCameraIds(devices)
      setCamera(backCameraId)
    }
    getCameras()
  }, [])

  const trackRef = useRef(null);

  const { type } = props;

  const handleZoom = async (ev) => {
    const { zoom } = cameraCapabilities;
    if (!zoom) {
      return;
    }

    if (ev.distance > 2) {
      const { min, max, step } = zoom;

      const nbOfSteps = (max - min + 1) / step;

      const pinchStep = 4 / nbOfSteps;

      const currentStep = (ev.scale + lastScale - 1) / pinchStep;

      const zoomValue = Math.max(min, Math.min(((Math.round(Math.min(currentStep, nbOfSteps)) * 0.1) + 1), max));

      trackRef.current.applyConstraints({
        advanced: [{ zoom: zoomValue }],
      });

      setCameraZoom(zoomValue);
    }
  }

  const handleZoomStart = () => setShowZoom(true)

  const handleZoomEnd = (e) => {
    const { zoom } = cameraCapabilities;
    if (!zoom) {
      return;
    }

    if (cameraZoom < zoom.max && cameraZoom > zoom.min) setLastScale(lastScale + (e.scale - 1))
    setShowZoom(false);
  }

  const handleZoomIn = (e) => {
    if (cameraZoom <= 1) {
      trackRef.current.applyConstraints({
        advanced: [{ zoom: 1 }],
      });
      setCameraZoom(1);
    } else {
      handleZoom(e)
    }
  }

  const getBackCameraId = (devices) => {
    const kinds = ['videoInput', 'videoinput']
    const videoInputs = devices.filter(({ kind }) => kinds.includes(kind));
    const backCameras = videoInputs.filter(({ label }) => /back|rear|environment/gi.test(label));
    if (backCameras && backCameras.length) {
      if (backCameras.length === 1) {
        return backCameras[0].deviceId;
      }
      return backCameras[1].deviceId
    }
    if (backCameras.length === 0 && videoInputs.length > 0) return videoInputs[videoInputs.length - 1].deviceId;
    
    return null;
  }

  const setCameraIds = (devices) => {
    const kinds = ['videoInput', 'videoinput']
    const videoInputs = devices.filter(({ kind, deviceId }) => kinds.includes(kind) && deviceId).map(c => c.deviceId);
    setCameras(videoInputs)
  }
  

  const toggleCameraId = () => {
    setError('');
    const currentCamIndex = cameras.indexOf(camera)
    if (currentCamIndex + 1 === cameras.length) setCamera(cameras[0])
    else setCamera(cameras[currentCamIndex + 1])
  }

  const streamErrors = {
    notDetected: "La caméra arrière de votre appareil n’est pas détectée.",
    notAuthorised: "Vous devez autoriser votre navigateur à utiliser la caméra sur cette page.",
    reserved: "Votre caméra est déjà utilisée par au moins une autre application. Fermez ces applications et réessayez.",
    notSupported: `Votre navigateur ne supporte pas l'usage de la caméra.
      Vous pouvez tout de même prendre vos photos et vidéos en dehors de l'application CheckAuto, via votre appareil, 
      puis appuyer sur "Galerie" afin de sélectionner les médias à importer directement depuis votre téléphone.`,
  }

  const appendedProps = {
    ...props,
    streamErrors,
    setCameraCapabilities,
    camera,
    cameraCapabilities,
    showZoom,
    cameraZoom,
    trackRef,
    error,
    setError,
    toggleCameraId
  }

  return (
    <Hammer
      // onPinch={handleZoom}
      onPinchEnd={handleZoomEnd}
      onPinchStart={handleZoomStart}
      onPinchOut={handleZoom}
      onPinchIn={handleZoomIn}
      options={{
        recognizers: {
          pinch: { enable: true }
        }
      }}
    >
      {
        camera !== 'initial' ? (
          <div className={styles.photoBlock}>
            {
              type === 'photo' ? (
                <Photo
                  {...appendedProps }
                />
              ) : (
                <VideoContainer
                  {...appendedProps }
                />
              )
            }
          </div>
        ) : <div className={styles.photoBlock} />
      }
    </Hammer>
  )
}