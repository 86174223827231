import React from 'react';
import Hammer from 'react-hammerjs';
import Swiper from 'components/Swiper';
import styled, { keyframes, css } from "styled-components";
import SlideContent from './SlideContent';
import FullScreenMenu from './FullScreenMenu';
import styles from './galery.module.css';

export default ({
  data,
  fileFullScreen,
  setFileFullScreen,
  handleSending,
  setCheckedElements
}) => {

  const panDown = (ev) => {
    const { additionalEvent, target, distance } = ev;
    const currentSlideIndex = data.findIndex(slide => slide.url === target.src || slide.thumbnailUrl === target.src);

    if (currentSlideIndex !== -1 && additionalEvent === "pandown" && distance > 100 && fileFullScreen.action === "open") {
      const selector = `[data-id="${data[currentSlideIndex].id}"]`;
      const el = document.querySelector(selector);
      const type = data[currentSlideIndex].contentType.split('/')[0];

      if (el) {
        const  { top, left } = el.getBoundingClientRect();
        
        setFileFullScreen({
          thumbnailUrl: data[currentSlideIndex].thumbnailUrl,
          url: data[currentSlideIndex].url,
          action: 'close',
          type,
          top,
          left,
          index: currentSlideIndex,
        });
      } else {
        setFileFullScreen({
          thumbnailUrl: data[currentSlideIndex].thumbnailUrl,
          url: data[currentSlideIndex].url,
          ...fileFullScreen,
          action: 'close',
          type,
          top: 9999,
          left: 9999,
          index: currentSlideIndex,
        });
      }
    }
    return;
  }

  const inlightbox = keyframes`
    0% { 
      position: fixed;
      height: 24.5vw;
      left: ${fileFullScreen.left}px;
      top: ${fileFullScreen.top}px;
    }
    100% {
      height: calc(100% - 145px);
      width: 100%;
      top: 89px;
      left: 0;
    }
  `;

  const outlightbox = keyframes`
    0% { 
      height: calc(100% - 145px);
      width: 100%;
      top: 89px;
      left: 0;
    }
    99% {
      position: fixed;
      width: 24.5vw;
      height: 24.5vw;
      left: ${fileFullScreen.left}px;
      top: ${fileFullScreen.top}px;
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  `;

  const openFullScreenAnimation = css`${inlightbox} 0.5s forwards`;
  const closeFullScreenAnimation = css`${outlightbox} 0.5s forwards`;


  const renderAnimation = () => {
    if (fileFullScreen.action === 'open') {
      return openFullScreenAnimation
    }

    if (fileFullScreen.action === 'close') {
      setTimeout(() => {
        setFileFullScreen({
          ...fileFullScreen,
          action: null,
        })
      }, 500)
      return closeFullScreenAnimation
    }

    return
  }

  const renderWidth = () => {
    if (fileFullScreen.action === 'open') {
      return '100%'
    }
    return '0'
  }

  const AnimatedDiv = styled.div`
    animation: ${renderAnimation()};
    width: ${renderWidth()};
  `

  const swiperParams = {
    slidesPerView: 1,
    lazy: true,
  }

  return (
    <Hammer
      onPan={panDown}
      direction='DIRECTION_ALL'
      options={{
        recognizers: {
          pan: {
            threshold: 100
          }
        }
      }}
    >
      <div >
        <AnimatedDiv className={styles.fullScreenBlock} >
          <Swiper
            data={data}
            render={SlideContent}
            index={fileFullScreen.index}
            params={swiperParams}
          />
          {
            fileFullScreen.action === 'open' && (
              <FullScreenMenu data={data} handleSending={handleSending} setCheckedElements={setCheckedElements} />
            )
          }
        </AnimatedDiv>
      </div>
    </Hammer>
  )
}