import React, { useState, Fragment, useContext } from 'react';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AlertContext } from 'context/Alert';
import uploadStream from 'services/uploadStream';
import GaleryContent from './GaleryContent';
import FullScreen from './FullScreen';
import Sender from './Sender';
import styles from './galery.module.css';

export default ({
  userId,
  userEmail,
  checkedElements,
  setCheckedElements,
  data,
  isSending,
  setIsSending,
  selecting,
  setSelecting,
  handleSending,
  handleDeleting,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [fileFullScreen, setFileFullScreen] = useState({ action: null });

  const { setAlert } = useContext(AlertContext);

  const groupByDate = (data) => {
    return data.reduce((rv, x) => {
      const date = new Date(x.timeCreated).toLocaleDateString();
      (rv[date] = rv[date] || []).push(x);
      return rv;
    }, {});
  };

  const groupedData = groupByDate(data)
  const dates = Object.keys(groupedData)

  const toggleSelecting = () => {
    if (selecting) setCheckedElements([])
    setSelecting(!selecting);
  }

  const cancelSending = () => {
    setIsSending(false);
    setFileFullScreen({ action: null })
  };

  const closeFullScreen = () => setFileFullScreen({
    ...fileFullScreen,
    action: 'close'
  });

  const toggleCheckElement = (value) => {
    const index = checkedElements.findIndex((checkedElement) => checkedElement.url === value.url)
    if (index !== -1) {
      const filtredValues = checkedElements.filter((el) => el.url !== value.url);
      setCheckedElements(filtredValues)
    }
    else setCheckedElements([...checkedElements, value]);
  }

  // events 
  const handleTap = (e, url) => {
    if (!selecting) {
      const { src } = e.target;
      const { top, left } = e.target.getBoundingClientRect();
      const fileData = data.find((row) => row.url === src || row.thumbnailUrl === src)
      const index = data.findIndex((row) => row.url === src || row.thumbnailUrl === src)
      const type = fileData.contentType.split('/')[0]
      setFileFullScreen({
        type,
        url: fileData.url,
        thumbnailUrl: fileData.thumbnailUrl,
        index,
        top,
        left,
        action: 'open'
      })
    } else {
      toggleCheckElement(url)
    }
  }

  const handlePress = (url) => {
    toggleCheckElement(url)
    setSelecting(true)
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    const LIMIT_SIZE_FILE_MB = 100;
    if (e.target.files && e.target.files.length > 0) {
      const { files } = e.target;
      if (files[0].size/1024/1024 > LIMIT_SIZE_FILE_MB) {
        setAlert({
          type: 'error',
          title: `Votre fichier dépasse ${LIMIT_SIZE_FILE_MB} Mo`,
          text: `Veuillez sélectionner un fichier qui ne dépasse pas ${LIMIT_SIZE_FILE_MB} Mo`,
          open: true,
        });
        return;
      }
      setIsUploading(true);
      const promises = Array.from(files).map(file => {
        uploadStream(false, file, file.type, userId, setAlert)
      }
      );
      await Promise.all(promises);
      setIsUploading(false);
    }
  }

  return (
    <Fragment>
      <div className={styles.topGalery}>
        {
          !isSending && fileFullScreen.action !== 'open' && (
            <Button
              color="primary"
              onClick={toggleSelecting}
            >
              <span style={{ color: selecting ? 'initial' : '#54A8DE' }}>{selecting ? 'Annuler' : 'Sélectionner'}</span>
            </Button>
          )
        }
        {
          isSending && (
            <Button
              color="primary"
              onClick={cancelSending}
            >
              Annuler
            </Button>
          )
        }
        {
          !isSending && fileFullScreen.action === "open" && (
            <Button
              color="primary"
              onClick={closeFullScreen}
              style={{ float: 'left' }}
            >
              <ArrowBackIcon color="primary" />
            </Button>
          )
        }
      </div>
      {
        !isSending ? (
          <div className={styles.galery}>
            {
              dates.map((date) => (
                <div key={date}>
                  <div className={styles.date}>{date}</div>
                  <div>
                    {
                      groupedData[date].map((row, index) => (
                        <GaleryContent
                          key={index}
                          data={row}
                          handleTap={handleTap}
                          handlePress={handlePress}
                          checkedElements={checkedElements}
                          selecting={selecting}
                        />
                      ))
                    }
                  </div>
                </div>
              ))
            }
            {
              data.length > 0 && <FullScreen
                data={data}
                fileFullScreen={fileFullScreen}
                setFileFullScreen={setFileFullScreen}
                handleSending={handleSending}
                handleDeleting={handleDeleting}
                setCheckedElements={setCheckedElements}
              />
            }
            <input type="file" id="upload-btn" style={{ display: "none" }} />
            <Button
              className={styles.addButton}
              color="primary"
              variant="contained"
              htmlFor="upload"
              component="label"
            >
              {
                isUploading ? 'loading' : <img src="add.svg" alt="" />
              }
              <input
                id="upload"
                type="file"
                accept="image/*,video/*"
                style={{ display: "none" }}
                onChange={handleUpload}
              />
            </Button>
          </div>
        ) : (
            <div className={styles.galery}>
              <Sender
                userEmail={userEmail}
                checkedElements={checkedElements}
                setIsSending={setIsSending}
                data={data}
                toggleCheckElement={toggleCheckElement}
                handleTap={handleTap}
                selecting={selecting}
                setCheckedElements={setCheckedElements}
                setSelecting={setSelecting}
              />
            </div>
          )
      }
    </Fragment>
  )
}
