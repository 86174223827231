import axios from 'axios';
import config from 'config/config';

export default async (sessionId, callBack) => {
  try {
    const data = {
      sessionId,
      resolution: '640x480',
      outputMode: 'composed'
    }
    const result = await axios.post(`${config.tokBoxUrl}/tokbox/startArchive`, data)
    const { id } = result.data;
    callBack(id)
    return result.data;
  } catch (e) {
    console.error(e)
  }
}
