import React, { useEffect, useState } from 'react';
import Swiper from 'swiper';

export default ({
  data,
  render,
  renderProps,
  index,
  params,
}) => {

  let [mySwiper, setMySwiper] = useState(null) 

  useEffect(()=> {
    const swiper = new Swiper('.swiper-container', {
      ...params,
    } || {});
    setMySwiper(swiper)
    window.swiper = swiper;
  }, [params])

  if (mySwiper) {
    mySwiper.slideTo(index, 0)
  }

  return (
    <div className="swiper-container">
      <div className="swiper-wrapper">
        {
          data.map((row, index) => (
            <div className="swiper-slide" key={index}>
              {
                render({
                  className: "swiper-slide",
                  data: row,
                  ...renderProps,
                })
              }
            </div>
          ))
        }
      </div>
  </div>
  )
}