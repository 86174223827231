import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import Swiper from 'components/Swiper';
import { CheckListContext } from 'context/CheckList';
import sendMediaCheck from 'services/sendMediaCheck';
import styles from './galery.module.css';
import GaleryContent from './GaleryContent';
import { Formik } from 'formik';
import { TextField, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ToDo from '../ToDo';

const CssTextField = withStyles({
  root: {
    '& label': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E0E0E0',
        borderRadius: '2px',
      },
      '&:hover fieldset': {
        borderColor: '#E0E0E0',
        borderRadius: '2px',
      },
    },
    '& .MuiOutlinedInput-input': {
      color: 'white',
    }
  },
})(TextField);

const oldLicensePlate = /^[0-9]{1,4}[A-HJ-NP-TV-Z]{1,4}[0-9]{1,2}$/;
const newLicensePlate = /^[A-HJ-NP-TV-Z]{1,2}[0-9]{1,3}[A-HJ-NP-TV-Z]{1,2}$/;
const emailRegex = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gmi;

const validationSchema = () => (
  Yup.object({
    email: Yup.string()
      .ensure()
      .required('Le champ e-mail est obligatoire')
      .matches(emailRegex, "l'e-mail doit être un e-mail valide"),
    licensePlate: Yup.string()
      .ensure()
      .test('licensePlate',
        "L'immatriculation Doit contenir que des chiffres et des majuscules",
        value => {
        if (value) {
          return (
            Yup.string()
              .matches(oldLicensePlate)
              .isValidSync(value) ||
            Yup.string()
              .matches(newLicensePlate)
              .isValidSync(value)
          );
        }
        return true;
      })
      .required('Le champ immatriculation est obligatoire'),
    phoneNumber: Yup.string()
      .required('Le champ téléphone est obligatoire')
      .matches(phoneRegex , 'Le numéro de téléphone est invalide'),
    name: Yup.string()
      .required('Le champ nom et prénom est obligatoire'),
  })
)

const Form = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  apiError,
  selectedMediaError,
  setFieldValue,
  isValid,
  dirty,
  isSubmitting,
  todoList
}) => {

  const onSelectForm = (e) => {
      e.persist();
      setTimeout(() => {
        e.target.scrollIntoView({
          block: "start",
          behavior: 'smooth',
        });
      }, 300);
  }

  return (
    <>
      <ToDo todoList={todoList} />
      <form onSubmit={handleSubmit}>
        <div className={styles.licensePlate}>
          <CssTextField
            type="text"
            name="licensePlate"
            label="Immatriculation"
            fullWidth
            margin="normal"
            variant="outlined"
            value={values.licensePlate}
            onChange={(e) => setFieldValue("licensePlate", e.target.value.toUpperCase().trim())}
            error={!!errors.licensePlate}
            onFocus={(e) => onSelectForm(e)}
          />
          {errors.licensePlate && <p className={styles.error}>{errors.licensePlate}</p>}
        </div>
        <div className={styles.name}>
          <CssTextField
            type="text"
            name="name"
            label="Nom & Prénom du client"
            fullWidth
            margin="normal"
            variant="outlined"
            value={values.name}
            onChange={handleChange}
            error={!!errors.name}
            onFocus={(e) => onSelectForm(e)}
            onBlur={(e)=> setFieldValue("name", e.target.value.trim())}
          />
          {errors.name && <p className={styles.error}>{errors.name}</p>}
        </div>
        <div className={styles.email}>
          <CssTextField
            type="text"
            name="email"
            label="Email client"
            fullWidth
            margin="normal"
            variant="outlined"
            value={values.email}
            onChange={(e) => setFieldValue("email", e.target.value.trim())}
            error={!!errors.email}
            onFocus={(e) => onSelectForm(e)}
          />
          {errors.email && <p className={styles.error}>{errors.email}</p>}
        </div>
        <div className={styles.phoneNumber}>
          <CssTextField
            type="text"
            name="phoneNumber"
            label="Téléphone client"
            fullWidth
            margin="normal"
            variant="outlined"
            value={values.phoneNumber}
            onChange={(e) => setFieldValue("phoneNumber", e.target.value.trim())}
            error={!!errors.phoneNumber}
            onFocus={(e) => onSelectForm(e)}
          />
          {errors.phoneNumber && <p className={styles.error}>{errors.phoneNumber}</p>}
        </div>
        <CssTextField
          multiline
          rows="4"
          name="comment"
          label="Commentaire"
          fullWidth
          margin="normal"
          variant="outlined"
          value={values.comment}
          onChange={handleChange}
        />
        <div className={styles.error}>{selectedMediaError || apiError} </div>
        <div className={styles.formBtn}>
          <Button
            className={styles.sendBtn}
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !!selectedMediaError.length ||
              !isValid ||
              !dirty ||
              isSubmitting
            }
          >
            {isSubmitting ? 'En cours...' : 'Valider' }
          </Button>
        </div>
      </form>
    </>
  )
}

export default ({
  checkedElements,
  setIsSending,
  data,
  toggleCheckElement,
  handleTap,
  selecting,
  setCheckedElements,
  setSelecting,
  userEmail
}) => {

  const renderProps = {
    checkedElements,
    toggleCheckElement,
    handleTap,
    selecting,
    isInSlide: true,
  }

  const history = useHistory();

  const [apiError, setApiError] = useState('');
  const [selectedMediaError, setSelectedMediaError] = useState('');

  const { getToDoList, clearToDoList, distribution, technicalInspectionDate } = useContext(CheckListContext);
  const todoList = getToDoList();

  const firstSlideIndex = () => {
    const index = data.findIndex((el) => el.url === checkedElements[0])
    if (data.length === index + 1) return index - 1;
    return index;
  }

  useEffect(() => {
    if (!checkedElements.length) {
      setSelectedMediaError('Aucun média n\'a été sélectionné');
      return; 
    }
    setSelectedMediaError('');
  }, [checkedElements])

  const sendMail = async (values) => {
    setApiError('');
    const { comment, licensePlate, email, phoneNumber, name  } = values;
    const result = await sendMediaCheck(
        comment,
        licensePlate,
        email,
        checkedElements,
        phoneNumber,
        name,
        userEmail,
        todoList,
        distribution, 
        technicalInspectionDate
      );

    if (result && !result.error) {
      history.push('/congrats');
      setCheckedElements([]);
      clearToDoList();
      setSelecting(false);
      setIsSending(false);
    } else {
      console.error(result?.error);
      setApiError('Une erreur s\'est produite');
    }
  }

  const swiperParams = {
    slidesPerView: 2,
    spaceBetween: 5,
    freeMode: true,
    lazy: true,
    startSlide: 4
  }

  return (
    <div className={styles.sending}>
      <Swiper
        data={data}
        render={GaleryContent}
        renderProps={renderProps}
        index={firstSlideIndex()}
        params={swiperParams}
      />
      <div className={styles.formSender} >
        <Formik
          validationSchema={() => validationSchema()}
          onSubmit={async values => {
            await sendMail(values)
          }}
          initialValues={{
            licensePlate: '',
            email: '',
            comment: '',
            phoneNumber: '',
            name: '',
          }}
        >
          {props => <Form {...props} todoList={todoList} apiError={apiError} selectedMediaError={selectedMediaError} />}
        </Formik>
      </div>
    </div>
  )
}