import React, { Fragment, useState } from 'react';
import { Button, Slide } from '@material-ui/core';
import DeleteSnackBar from 'pages/media/DeleteSnackBar';
import styles from './galery.module.css';

export default (props) => {
  const { data, setCheckedElements } = props;
  const [fullScreenPopup, setFullScreenPopup] = useState(false);
  const [transition, setTransition] = useState(undefined);

  const TransitionUp = (props) => <Slide {...props} direction="up" />;

  const handleOpenSnackBar = Transition => () => {
    setTransition(() => Transition);
    setFullScreenPopup(true);
  };

  const handleSending = async () => {
    const { activeIndex } = window.swiper;
    await setCheckedElements([
      {
        url: data[activeIndex].url,
        contentType: data[activeIndex].contentType
      }
  ])
    props.handleSending();
  };

  return (
    <Fragment>
      <div className={styles.fullScreenMenu} >
        <Button
          variant="outlined"
          color="primary"
          className={styles.fullScreenMenu_Button}
          startIcon={<img alt="" src="send.svg" />}
          onClick={handleSending}
        >
          Envoyer
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={styles.fullScreenMenu_Button}
          startIcon={<img alt="" src="trash.svg" />}
          onClick={handleOpenSnackBar(TransitionUp)}
        >
          Supprimer 
        </Button>
      </div>
      <DeleteSnackBar
        open={fullScreenPopup}
        setOpenSnackBar={setFullScreenPopup}
        transition={transition}
        data={data}
      />
    </Fragment>
  )

}