import React, { Suspense, Fragment, useRef } from 'react';

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Checkbox from 'components/CustomCheckBox'
import useIntersectionObserver from 'utils/useIntersectionObserver';
import Hammer from 'react-hammerjs';
import Image from './Image';
import styles from './galery.module.css';

export default ({
  data,
  handleTap,
  handlePress,
  checkedElements,
  isInSlide,
  className,
  selecting
}) => {
  const elementRef = useRef(null);

  const [isVisible, setIsVisible] = React.useState(false);

  if (elementRef) {
    useIntersectionObserver({
      target: elementRef,
      onIntersect: ([{ isIntersecting }], observerElement) => {
        if (isIntersecting) {
          setIsVisible(true);
          observerElement.unobserve(elementRef.current);
        }
      }
    });
  }

  const { url, thumbnailUrl, contentType, id } = data;

  const isChecked = () => !!checkedElements.find((checkedElement) => checkedElement.url === url);

  const renderContent = () => {
    const fileType = contentType.split('/')[0];

    return (
      <div
        className={styles.contentBlock}
        style={{
          background: (isChecked()) ? 'white' : 'initial',
          height: isInSlide && '200px',
          width: isInSlide && '100%',
        }}
      >
        <div ref={elementRef}>
          {
            isVisible && (
              <Fragment>
                {
                  fileType === 'image' ? (
                    <Image
                      thumb={thumbnailUrl || url}
                      alt=""
                      src={thumbnailUrl || url}
                      id={id}
                    />
                  ) : (
                      <Fragment>
                        <span className={styles.videoIcon}>
                          <PlayCircleFilledIcon />
                        </span>
                        <video
                          className={styles.mediaContent}
                          src={url}
                          data-id={id || null}
                          ref={elementRef}
                          playsInline
                          loop
                          muted
                          autoPlay
                        />
                      </Fragment>
                    )
                }
                {
                  selecting && (
                    <Checkbox
                      checked={isChecked()}
                    />
                  )
                }
              </Fragment>
            )
          }
        </div>
      </div>
    )
  }

  return (
    <Suspense fallback={<div>loading</div>}>
      <Hammer
        onPress={() => handlePress({url, contentType})}
        onTap={(e) => handleTap(e, {url, contentType})}
      >
        {renderContent()}
      </Hammer>
    </Suspense>
  )
}