/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import styles from './notice.module.css';

export default () => {
  const history = useHistory();
  const handleBackButton = () => history.push('/');

  useEffect(() => {
    window.OneTrust?.NoticeApi?.Initialized.then(() => {
      window.OneTrust?.NoticeApi?.LoadNotices(["https://privacyportal-cdn.onetrust.com/9e3840d9-d3d2-4665-ac3b-b1cdce104745/privacy-notices/f30ed2e1-3a08-40f1-8988-226bf5b2822f.json"]);
    });
  }, []);

  return (
    <div className={styles.notice}>
      <div className={styles.logoContainer}>
        <button className={styles.back} onClick={handleBackButton}>
          <img src="back.svg" alt="" />
          <span>Revenir a la page de connexion</span>
        </button>
        <img className={styles.logo} src="checkAutologo@2x.svg" alt="" />
      </div>
      <div className="otnotice-language-dropdown-container">
        <select id="otnotice-language-dropdown" aria-label="language selector" />
      </div>
      <div id="otnotice-f30ed2e1-3a08-40f1-8988-226bf5b2822f" className="otnotice" />
    </div>
  );
};

