import React from "react";
import { makeStyles } from '@material-ui/core/styles';

// import withStyle from "./withProgressBar";

const useStyles = makeStyles(() => ({

  progressBar: ({percent}) => ({
    background: '#D8D8D8',
    '& .progress-bar-fill': {
      width: `${percent}%`,
      height: '7px',
      background: '#1E3B81',
      transition: 'width 0.5s ease-in-out',
    }
  }),
}));

const ProgressBar = ({percent}) => {
  const classes = useStyles({percent});
  return (
      <div className={classes.progressBar}>
        <div className="progress-bar-fill" />
      </div>
  )
}

export default ProgressBar;
