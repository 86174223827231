import axios from 'axios';
import config from 'config/config';

export default async (path, contentType, uri) => {
  try {
    return await axios.post(`${config.managerUrl}/medias/thumbnail`, {
      path,
      contentType,
      uri,
    })
  } catch (e) {
    console.error(e)
  }

}