import axios from 'axios';
import config from 'config/config';

export default async (archiveId, setAlert, userId, setIsconfirmed) => {
  setAlert({
    type: 'loading',
    open: true,
  });
  try {
    const result = await axios.post(`${config.tokBoxUrl}/tokbox/stopArchive`, { archiveId });
    if (result.data) {
      setAlert({
        type: 'confirmation',
        title: 'Valider la vidéo',
        text: 'Vous voulez vraiment valider la vidéo',
        open: true,
        handleStop: () => setIsconfirmed(false),
        handleConfirm: () => setIsconfirmed(true),
        handleVideoStream: () => null,
      });
      return result.data.url;
    }
  } catch (e) {
    console.error(e);
  }
}
