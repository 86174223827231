import React, { useRef, useState, useEffect, Fragment } from 'react';
import uploadStream from 'services/uploadStream';
import isIOS from 'utils/isIOS';
import RecordButton from './RecordButton';
import styles from './camera.module.css';

const { mediaDevices } = navigator;

let isConfirmed = false;

const constants = {
  SUPPORTS_IMAGE_CAPTURE: 'ImageCapture' in window,
  SUPPORTS_MEDIA_DEVICES: 'mediaDevices' in navigator,
}

export default ({
  userId,
  type,
  setCameraCapabilities,
  cameraCapabilities,
  error,
  setError,
  timer,
  setTimer,
  getBackCameraId,
  setAlert,
  trackRef,
  streamErrors,
  camera
}) => {
  const [videoState, setVideoState] = useState('stop');
  const [flash, setFlash] = useState('flash');
  const [photoCapabilities, setphotoCapabilities] = useState(null);

  const streamRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    setVideoState('stop')
    const { current } = streamRef;
    if (current && current.mediaRecorder && current.mediaRecorder.state === 'recording') {
      current.mediaRecorder.stop()
    }
  }, [])

  const mimeType = `video/${isIOS() ? 'mp4' : 'webm'}`;

  useEffect(() => {
    function getStream() {
      mediaDevices.getUserMedia(
        {
          video: {
            width: { min: 1024, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 720, max: 1080 },
            frameRate: { min: 15, ideal: 24 },
            ...(camera ? { deviceId: camera } : { facingMode: { exact: 'environment' } })
          },
          audio: true
        }
      )
      .then((stream) => {
        if(streamRef.current) {
          streamRef.current.muted = true;
          window.stream = stream;
          if ('srcObject' in streamRef.current) streamRef.current.srcObject = stream;
          else streamRef.current.src = window.URL.createObjectURL(stream)
          if (error) setError('')

          streamRef.current.onloadedmetadata = async () => {
            const track = stream.getVideoTracks()[0];
            trackRef.current = track;

            if (constants.SUPPORTS_IMAGE_CAPTURE) {
              const capture = new ImageCapture(track);
              const result = await capture.getPhotoCapabilities();
              setphotoCapabilities(result)
            }

            const capabilities = track.getCapabilities()
            setCameraCapabilities(capabilities)

            streamRef.current.play()
          }

          const options = {
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 2500000,
            mimeType
          };

          const recorder = new MediaRecorder(stream, options);
          streamRef.current.mediaRecorder = recorder

          const { mediaRecorder } = streamRef.current;

          let chunks = [];

          mediaRecorder.ondataavailable = (ev) => {
            chunks.push(ev.data);
          }

          mediaRecorder.onstop = (ev) => {
            setTimer(0);
            const blob = new Blob(chunks, { 'type': mimeType, });
            chunks = [];
            const url = window.URL.createObjectURL(blob);
            if (videoRef.current) {
              videoRef.current.onload = () => {
                URL.revokeObjectURL(url);
              };
              videoRef.current.src = url
              if (isConfirmed) {
                uploadStream(false, blob, mimeType, userId, setAlert, isConfirmed)
              }
            }
          }
        }
      })
      .catch((e) => {
        console.error(e);
        if (e.name === 'NotAllowedError') setError(streamErrors.notAuthorised);
        else if (e.constraint && e.constraint === 'facingMode') setError(streamErrors.notDetected);
        else if (e.message === 'Failed to allocate videosource') setError(streamErrors.reserved);
        else setError(streamErrors.notSupported);
      })
    }

    if (!error) getStream();

    return () => {
      if (streamRef && streamRef.current && streamRef.current.srcObject) {
        streamRef.current.srcObject.getTracks().map(t => t.stop()); // eslint-disable-line
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, userId, type, camera])

  useEffect(() => {
    if (videoState === 'start') {
      let timeOut = null;
      if (timer === 40) {
        setVideoState('stop');
        recordVideo();
        clearTimeout(timeOut)
      } else {
        timeOut = setTimeout(() => {
          setTimer(timer + 1);
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, videoState])

  const startVideo = () => {
    streamRef.current.mediaRecorder.start();
  }

  const stopVideo = () => {
    if (videoRef.current) videoRef.current.classList.add("taken");
    setAlert({
      type: 'confirmation',
      title: 'Valider la vidéo',
      text: 'Vous voulez vraiment valider la vidéo',
      open: true,
      handleStop: () => isConfirmed = false,
      handleConfirm: () => isConfirmed = true,
      handleVideoStream: () => streamRef.current.mediaRecorder.stop()
    });
  }

  const recordVideo = () => {
    const hasTorch = cameraCapabilities.torch !== undefined;

    if (videoState === 'stop') {
      if (flash === 'flash' && hasTorch) {
        trackRef.current.applyConstraints({
          advanced: [{ torch: true }]
        })
      }
      startVideo()
      setVideoState('start')
    } else {
      if (flash === 'flash' && hasTorch) {
        trackRef.current.applyConstraints({
          advanced: [{ torch: false }]
        })
      }
      setVideoState('stop')
      stopVideo()
    }
  }

  const toggleFlash = () => {
    if (flash === 'off') setFlash('flash')
    else setFlash('off')
  }

  return (
    <Fragment>
      <video autoPlay ref={streamRef} muted playsInline className={styles.videoStream} />
      { photoCapabilities && photoCapabilities.fillLightMode && photoCapabilities.fillLightMode.length !== 0 &&
        <button className={styles.flash} onClick={toggleFlash}>
          <img alt="" src={flash === 'off' ? 'flash-off.png' : 'flash.png'} />
        </button>
      }
      <video style={{ display: 'none' }} ref={videoRef} />
      <RecordButton videoState={videoState} recordVideo={recordVideo} />
    </Fragment>
  )
}