import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import { AlertProvider } from './context/Alert';
import { CheckListProvider } from './context/CheckList';
import fire from 'config/fire';
import Media from 'pages/media';
import Login from 'pages/login';
import Congrats from 'pages/congrats';
import Landing from 'pages/landing';
import PrivacyNotice from 'pages/privacyNotice';

import theme from './theme';

function App() {
  const [isLogged, setIsLogged] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [mount, setMount] = useState(false);

  const authListener = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLogged(true)
        setUserId(user.uid)
        setUserEmail(user.email)
      }
      else {
        setIsLogged(false)
        setUserId(null)
      }
      setMount(true)
    })
  }

  useEffect(() => authListener(), []);

  return (
    <div className="App" style={{ height: "100vh" }}>
      <ThemeProvider theme={theme}>
        {
          mount && (
            <AlertProvider>
              <CheckListProvider>
                <BrowserRouter>
                  <Switch>
                    <Route
                      path={"/landing"}
                      exact
                      render={() => <Landing userId={userId} />}
                    />
                    <Route
                      path={"/(|photo|galerie|video)"}
                      exact
                      render={() => isLogged ? <Media userId={userId} userEmail={userEmail} /> : <Login />}
                    />
                    <Route
                      path={"/login"}
                      exact
                      render={() => isLogged ? <Media userId={userId} userEmail={userEmail} /> : <Login />}
                    />
                    <Route
                      path={"/congrats"}
                      exact
                      render={() => isLogged ? <Congrats/> : <Login />}
                    />
                    <Route
                      path={"/notice"}
                      exact
                      render={() => <PrivacyNotice />}
                    />
                  </Switch>
                </BrowserRouter>
              </CheckListProvider>
            </AlertProvider>
          )
        }
      </ThemeProvider>
    </div>
  );
}

export default App;