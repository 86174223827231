import React, { Fragment } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import firebase from 'firebase';
import styles from './media.module.css'

const db = firebase.firestore();

export default ({
  setOpenSnackBar,
  transition,
  open,
  data,
  checkedElements,
  setCheckedElements,
}) => {

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  const deleteElements = async () => {
    const elements = checkedElements ? [...checkedElements] : [];

    const { activeIndex } = window.swiper;
    
    if (!checkedElements && data[activeIndex].url) {
      elements.push(data[activeIndex].url)
    }
 
    const ids = data.reduce((acc, cur)=>{
      if(elements.some(item =>  typeof item === "string" ? cur.url === item :(cur.url || cur.thumbnailUrl) === item.url)) acc.push(cur.id)
      return acc
    },[])

    const batch = db.batch();

    ids.forEach((id) => {
      const ref = db.collection("medias").doc(id);
      batch.delete(ref);
    })

    await batch.commit();
    await setOpenSnackBar(false);
    if (checkedElements) await setCheckedElements([])
  }

  const renderMessage = () => {
    return `Êtes vous sûr de vouloir supprimer ${checkedElements && checkedElements.length > 1 ? 'ces': 'cet'}  élément${checkedElements && checkedElements.length > 1 ? 's': ''} ?`
  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      TransitionComponent={transition}
      message={renderMessage()}
      className={styles.deletePopup}
      action={
        <Fragment>
          <Button className={styles.cancelButton} onClick={handleClose} color="primary" >Non, annuler</Button>
          <Button className={styles.confirmButton} color="secondary" onClick={deleteElements}>Oui, supprimer</Button>
        </Fragment>
      }
    />
  )
}