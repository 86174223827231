import axios from 'axios';
import firebase from 'firebase';
import config from 'config/config';
const db = firebase.firestore();

const getSession = async (userId) => {
  try {
    const result = await axios.post(`${config.tokBoxUrl}/tokbox/createSession`);
    await db.collection("tokboxSession").doc(userId).set({
      session: result.data
    })
    return result.data; 
  } catch (e) {
    console.error(e)
  }
}

const getToken = async (sessionId) => {
  try {
    const result = await axios.get(`${config.tokBoxUrl}/tokbox/getToken`, {
      headers: {
        sessionid: sessionId,
      }
    })
    return result.data;
  } catch (e) {
    console.error(e)
  }
}

export default async (userId) => {
  const session = await getSession(userId);
  if (session) {
    const token = await getToken(session);

    return {
      session,
      token,
    }
  }
  return;
}