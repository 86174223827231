import idb from 'services/idbService';
import firebase from 'firebase';
const db = firebase.firestore();

export default (userId, callBack) => {
  const date = new Date()
  date.setDate(date.getDate() - 30)
  window.unsubscribeSnapshot = db.collection("medias")
    .orderBy("timeCreated", "desc")
    .where("userId", "==", userId)
    .where ("timeCreated",">=", date.toISOString())
    .onSnapshot(async (snapshot) => {
      const data = [];
      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id})
      });

      await callBack(data)

      await idb.clear('Media').then(() => {
        data.forEach(async (row, index) => {
          await idb.set('Media', null, row)
        })
      })
    })
}