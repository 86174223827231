import React from 'react';
import styles from './congrats.module.css'
import { useHistory } from 'react-router-dom'

export default () => {
  const history = useHistory();

  const handleFinish = () => history.push('/')

  return (
    <div className={styles.congrats}>
    <div className={styles.header}>
      <img src="CombinedShape.svg" alt="" />
      <h2>Félicitations,</h2>
      <span>Votre rapport est terminé!</span>
    </div>
    <div className={styles.container}>
      <p>Un mail va être envoyé sur votre boîte mail, répondez en mettant votre devis</p>
      <p>Intégrez votre devis en pièce jointe et envoyez</p>
      <p>Le client reçoit automatiquement un mail avec la proposition de devis</p>
      <p>N’oubliez pas de consulter vos mails pour la réponse du client</p>
      <p>Il accepte ou refuse le devis</p>
      <p>je modifie ma facture en cas d'acceptation du devis</p>
      <p>Merci de consulter votre boîte mail afin de poursuivre la procédure.</p>
    </div>
    <div className={styles.footer}>
      <button onClick={handleFinish}>
        Terminer
      </button>
    </div>
  </div>
  )
}