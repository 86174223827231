import React, { Fragment } from 'react';
import styles from './header.module.css';
import firebase from 'firebase';
import ClearIcon from '@material-ui/icons/Clear';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export default ({
  type,
  checkedElements,
  clear,
  selecting,
  setSelecting,
}) => {
  const logOut = () => {
    firebase.auth().signOut().then(function() {
      // Sign-out successful.
    }).catch(function(error) {
      // An error happened.
    });
  }

  // const setTitle = () => {
  //   if (selecting && type=== 'galerie') {
  //     const plural = checkedElements.length > 1 ? 's' : ''
  //     return <div>
  //       <button className={styles.clear} onClick={clear}><ClearIcon/></button>
  //       {`${checkedElements.length} élément${plural} séléctionné${plural}`}
  //     </div>
  //   }
  //   return type;
  // }

  const galerySlecting = selecting && type === 'galerie';

  return (
    <Fragment>
      <div
      className={styles.header}
      style={{ textTransform: galerySlecting ? 'lowercase': 'capitalize' }}
      >
        <img alt="" src="logoinwhite.svg" className={styles.logo} />
        { (
          <button className={styles.logout} onClick={logOut}>
            <img src="checkAutoLogout.svg" alt="" />
          </button>
        )}
      </div>
      <div className={styles.bar} />
    </Fragment>
  )
}