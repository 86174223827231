import { openDB } from 'idb'

const DATABASE_NAME = 'MEDIA';
const DATABASE_VERSION = 1;


const dbPromise = openDB(DATABASE_NAME, DATABASE_VERSION, {
  upgrade(db, oldVersion, newVersion, transaction) {

    if (oldVersion < DATABASE_VERSION) {
      db.createObjectStore('Media', { keyPath: 'id', autoIncrement: true });
    }
  },
});

const idb = {
  async get(tablespace, key) {
    return (await dbPromise).get(tablespace, key);
  },
  async getAll(tablespace, index) {
    return (await dbPromise).getAll(tablespace);
  },
  async set(tablespace, key, val) {
    if (key) {
      return (await dbPromise).put(tablespace, val, key);
    }
    return (await dbPromise).put(tablespace, val);
  },
  async delete(tablespace, key) {
    return (await dbPromise).delete(tablespace, key);
  },
  async clear(tablespace) {
    return (await dbPromise).clear(tablespace);
  },
  async keys(tablespace) {
    return (await dbPromise).getAllKeys(tablespace);
  },
};

export default idb;