import React, { useEffect, useState, useRef } from 'react';
import handleSession from 'services/tokBox/handleSession';
import startArchive from 'services/tokBox/startArchive';
import stopArchive from 'services/tokBox/stopArchive';
import confirmUplaodArchive from 'services/tokBox/confirmUplaodArchive';
import config from 'config/config';
import RecordButton from './RecordButton';
import Loader from '../Loader';
import styles from './camera.module.css';

export default ({
  userId,
  getBackCameraId,
  setCameraCapabilities,
  cameraCapabilities,
  error,
  setError,
  timer,
  setTimer,
  setAlert,
  type,
  trackRef,
  streamErrors,
  camera
}) => {
  const [sessionData, setSessionData] = useState({
    session: null,
    token: null,
  });
  const [archiveId, setArchiveId] = useState(null);
  const [photoCapabilities, setphotoCapabilities] = useState(null);
  const [flash, setFlash] = useState('flash');
  const [videoState, setVideoState] = useState('stop');
  const [isConfirmed, setIsconfirmed] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const streamRef = useRef(null);

  const toggleFlash = () => {
    if (flash === 'off') setFlash('flash')
    else setFlash('off')
  }

  const clearTracks = () => {
    if (streamRef && streamRef.current && streamRef.current.srcObject) {
      streamRef.current.srcObject.getTracks().map(t => t.stop()); // eslint-disable-line
    }
  }

  const recordVideo = async () => {
    const hasTorch = cameraCapabilities && cameraCapabilities.torch !== undefined;

    if (videoState === 'stop') {
      if (flash === 'flash' && hasTorch) {
        trackRef.current.applyConstraints({
          advanced: [{ torch: true }]
        })
      }

      startArchive(
        sessionData.session,
        (id) => setArchiveId(id)
      )
      setVideoState('start')
    } else {
      if (flash === 'flash' && hasTorch) {
        trackRef.current.applyConstraints({
          advanced: [{ torch: false }]
        })
      }
      setVideoState('stop');
    }
  };

  useEffect(() => {
    if (sessionData.token) {
      const session = window.OT.initSession(config.tokBoxKey, sessionData.session);
      const handleStream = async () => {
        const options = {
          ...(camera ? { videoSource: camera } : { facingMode: 'environment' }),
        }

        return window.OT.getUserMedia(options)
          .then((stream) => {
            if(streamRef.current) {
              streamRef.current.muted = true;
              window.stream = stream;
              if ('srcObject' in streamRef.current) streamRef.current.srcObject = stream;
              else streamRef.current.src = window.URL.createObjectURL(stream);
              if (error) setError('');

              streamRef.current.onloadedmetadata = async () => {
                const track = stream.getVideoTracks()[0];
                trackRef.current = track;

                if ('ImageCapture' in window) {
                  const capture = new ImageCapture(track);
                  const result = await capture.getPhotoCapabilities();
                  setphotoCapabilities(result)
                }

                const capabilities = track.getCapabilities()
                setCameraCapabilities(capabilities)

                streamRef.current.play()
              }

              const publisherOptions = {
                width: '100%',
                height: '100%',
                videoSource: stream.getVideoTracks()[0],
                audioSource: stream.getAudioTracks()[0]
              };

              return new Promise((resolve, reject) => {
                const publisher = window.OT.initPublisher(null, publisherOptions, (err) => {
                  if (err) {
                    console.error('erro init publisher', err)
                    clearTracks()
                    reject(err);
                  } else {
                    resolve(publisher);
                  }
                });
                publisher.on('destroyed', () => {
                  clearTracks()
                });
              });
            }
          }).catch((e) => {
            console.error(e);
            if (e.name === 'NotAllowedError') setError(streamErrors.notAuthorised);
            else if (e.constraint && e.constraint === 'facingMode') setError(streamErrors.notDetected);
            else if (e.message === 'Failed to allocate videosource') setError(streamErrors.reserved);
            else setError(streamErrors.notSupported);
          })
      }
      session.disconnect();
      session.connect(sessionData.token, (error) => {
        if (error) console.error(`session error`, error)
        if (!error) {
          handleStream().then((publisher) => {
            publisher.setVideoSource(camera)
            session.publish(publisher, (pubErr) => {
              if (pubErr) {
                console.error('There was an error publishing: ', pubErr.name, pubErr.message);
              }
            });
          }).catch((e) => console.error(e));
          setIsLoading(false);
        }
      })
    }

    return () => {
      clearTracks()
    }
  }, [userId, error, sessionData.token, type, camera]) //eslint-disable-line

  useEffect(() => {
    let timeOut = null;
    if (videoState === 'start') {
      if (timer === 40) {
        setVideoState('stop');
        recordVideo();
        setTimer(0);
        clearTimeout(timeOut)
      } else {
        timeOut = setTimeout(() => {
          setTimer(timer + 1);
        }, 1000);
      }
    } else {
      setTimer(0);
      clearTimeout(timeOut)
    }
  }, [timer, videoState]) //eslint-disable-line

  useEffect(() => {
    if (videoState === 'stop' && archiveId) {
      stopArchive(archiveId, setAlert, userId, setIsconfirmed);
    }
  }, [archiveId, videoState]) //eslint-disable-line

  useEffect(() => {
    const handleToken = async () => {
      const {
        session,
        token
      } = await handleSession(userId);

      setSessionData({
        session,
        token,
      })
    }
    handleToken()
  }, [userId, type])

  useEffect(() => {
    if (videoState === 'stop' && archiveId && isConfirmed !== null) {
      confirmUplaodArchive(archiveId, setAlert, isConfirmed, setIsconfirmed, userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed, archiveId]);

  if (isLoading) {
    return <Loader />
  }

  return sessionData.token && (
    <div>
      <video autoPlay ref={streamRef} muted playsInline className={styles.videoStream} />
      { photoCapabilities && photoCapabilities.fillLightMode && photoCapabilities.fillLightMode.length !== 0 &&
        <button className={styles.flash} onClick={toggleFlash}>
          <img alt="" src={flash === 'off' ? 'flash-off.png' : 'flash.png'} />
        </button>
      }
      <RecordButton videoState={videoState} recordVideo={recordVideo} />
    </div>
  )
}