export default [
  {
    type: "Niveaux",
    title: "Liquide du lave-glace",
    todo: null,
  },
  {
    type: "Niveaux",
    title: "Liquide de frein",
    todo: null,
  },
  {
    type: "Niveaux",
    title: "Liquide refroidissement",
    todo: null,
  },
  {
    type: "Niveaux",
    title: "Liquide de direction assitée",
    todo: null,
  },
  {
    type: "Niveaux",
    title: "Huile moteur",
    todo: null,
  },
  {
    type: "Sécurité",
    title: "Avertisseur sonore",
    todo: null,
  },
  {
    type: "Sécurité",
    title: "Ceintures de sécurité avant",
    todo: null,
  },
  {
    type: "Sécurité",
    title: "Ceintures de sécurité arrière",
    todo: null,
  },
  {
    type: "Sécurité",
    title: "Coffret de lampe et triangle de présignalisation ",
    todo: null,
  },
  {
    type: "Sécurité",
    title: "Gilet de sécurité",
    todo: null,
  },
  {
    type: "Visibilité",
    title: "Balais d’essuie-glace avant",
    todo: null,
  },
  {
    type: "Visibilité",
    title: "Balais d’essuie-glace arrière",
    todo: null,
  },
  {
    type: "Visibilité",
    title: "État du pare-brise",
    todo: null,
  },
  {
    type: "Visibilité",
    title: "Rétroviseur intérieur",
    todo: null,
  },
  {
    type: "Visibilité",
    title: "Rétroviseur extérieur",
    todo: null,
  },
  {
    type: "Divers",
    title: "Ligne d’échappement",
    todo: null,
  },
  {
    type: "Divers",
    title: "Courroies d’accessoires (hors distribution)",
    todo: null,
  },
  {
    type: "Divers",
    title: "Soufflets de cardan",
    todo: null,
  },
  {
    type: "Divers",
    title: "Batterie",
    todo: null,
  },
  {
    type: "Divers",
    title: "Filtre d’habitacle",
    todo: null,
  },
  {
    type: "Divers",
    title: "Plaque d’immatriculation avant",
    todo: null,
  },
  {
    type: "Divers",
    title: "Plaque d’immatriculation arrière",
    todo: null,
  },
  {
    type: "Divers",
    title: "Amortisseurs avant",
    todo: null,
  },
  {
    type: "Divers",
    title: "Amortisseurs arrière",
    todo: null,
  },
  {
    type: "Éclairage optique",
    title: "Code et phare arrière",
    todo: null,
  },
  {
    type: "Éclairage optique",
    title: "Clignotant avant",
    todo: null,
  },
  {
    type: "Éclairage optique",
    title: "Clignotant arrière",
    todo: null,
  },
  {
    type: "Éclairage optique",
    title: "Feux stop",
    todo: null,
  },
  {
    type: "Éclairage optique",
    title: "Feux anti-brouillard avant",
    todo: null,
  },
  {
    type: "Éclairage optique",
    title: "Feux anti-brouillard arrière",
    todo: null,
  },
  {
    type: "Éclairage optique",
    title: "Feux de recul arrière",
    todo: null,
  },
  {
    type: "Éclairage optique",
    title: "Feux de plaque d’immatriculation",
    todo: null,
  },
  {
    type: "Pneumatique",
    title: "Pression des pneux avants",
    todo: null,
  },
  {
    type: "Pneumatique",
    title: "Pression de la roue de secours",
    todo: null,
  },
  {
    type: "Pneumatique",
    title: "État des pneux avants",
    todo: null,
  },
  {
    type: "Pneumatique",
    title: "État des pneux arrières",
    todo: null,
  },
  {
    type: "Pneumatique",
    title: "État de la roue de secours",
    todo: null,
  },
  {
    type: "Freinage",
    title: "Plaquettes de frein avant",
    todo: null,
  },
  {
    type: "Freinage",
    title: "Plaquettes de frein arrière",
    todo: null,
  },
  {
    type: "Freinage",
    title: "Disques de frein avant ",
    todo: null,
  },
  {
    type: "Freinage",
    title: "Disques de frein arrière",
    todo: null,
  },
  {
    type: "Freinage",
    title: "Flexibles de frein avant",
    todo: null,
  },
  {
    type: "Freinage",
    title: "Flexibles de frein arrière",
    todo: null,
  },
  {
    type: "Freinage",
    title: "Frein de stationnement",
    todo: null,
  },
]