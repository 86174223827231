import React from 'react';
import { useHistory } from "react-router-dom";
import styles from './landing.module.css'

export default ({ userId }) => {
  const history = useHistory();

  function handleClick(){
    history.push('/')
  }
  return (
    <div className={styles.landing}>
      <img alt="" src="logoinwhite.svg" className={styles.logo} />
      <div className={styles.landingDetails}>
        <h3>Comment ça marche ?</h3>
        <p>
          Prenez en photo ou vidéo les réparations
      </p>
        <p>
          Cliquez sur la bibliothèque pour sélectionner vos photos et/ou vidéos
      </p>
        <p>
          Intégrez l’immatriculation du véhicule et l’adresse mail du client
      </p>
        <p>
          Cliquez sur envoyer
      </p>
        <p>
          Un mail va être envoyé sur votre boîte mail, répondez en intégrant votre devis et envoyez
      </p>
        <p>
          Le client reçoit automatiquement un mail avec la proposition de devis,
      </p>
        <p>
          Il accepte ou refuse le devis
      </p>
        <p>
          Je modifie l’OR et devis en cas d’acceptation
      </p>
      </div>
      <div className={styles.landingFooter}>
        <button onClick={handleClick}>
          Suivant
      </button>
      </div>
    </div>
  )
}