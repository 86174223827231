import axios from 'axios'
import config from 'config/config';

export default async (
  comment,
  licensePlate,
  email,
  checkedElements,
  phoneNumber,
  name,
  userEmail,
  todoList,
  distribution, 
  technicalInspectionDate
) => {
  try {
    const result = await axios.post(`${config.managerUrl}/mediaCheck`, {
      comment,
      licensePlate,
      urls: checkedElements,
      clientEmail: email,
      clientPhoneNumber: phoneNumber,
      garageEmail: userEmail,
      clientName: name,
      todoList: todoList.map(todo => todo?.title),
      distribution, 
      technicalInspectionDate: technicalInspectionDate ? new Date(technicalInspectionDate).toLocaleDateString('fr-FR', {
        timeZone: 'Europe/Paris',
      }) : '',
    })
    return result.data;
  } catch (e) {
    console.error(e)
  }
}