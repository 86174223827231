import React from 'react';
import styles from './camera.module.css';

export default ({
  videoState,
  recordVideo
}) => (
  <button onClick={recordVideo} className={styles.snap}>
  <div>
    <span
      style={{
        backgroundColor: 'red',
        width: videoState === "stop" ? '40px' : '20px',
        height: videoState === "stop" ? '40px' : '20px',
        borderRadius: videoState === "stop" ? '100%' : '5px',
      }}
    />
  </div>
</button>
)