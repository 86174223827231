import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";
import Header from 'components/Header';
import Camera from 'components/Camera';
import Galery from 'components/Galery';
import Checklist from 'components/Checklist';
import { BottomNavigation, BottomNavigationAction, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import idb from 'services/idbService'
import getMediaOnRealTime from 'services/getMediaOnRealTime';
import DeleteSnackBar from './DeleteSnackBar';
import styles from './media.module.css'


const useStyles = makeStyles({
  menu: {
    width: "100%",
    background: "#091F41",
    bottom: 0,
    position: 'fixed',
  },
  subMenu: {
    background: "white",
    bottom: "56px",
    position: 'fixed',
    height: "75px",
    padding: "0 52px",
    width: "calc(100% - 105px)",
  },
  menuItem: {
    color: "#8C8C8C"
  },
  subMenuItem: {
    color: "#091F41"
  }, 
  selected: {
    color: 'white !important',
  }
});

const TransitionUp = (props) => <Slide {...props} direction="up" />;

export default ({ userId, userEmail }) => {
  const [checkedElements, setCheckedElements] = useState([])
  const [selecting, setSelecting] = useState(false)
  const [data, setData] = useState([])
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [isSending, setIsSending] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const route = location.pathname.replace('/', '');
  const type = route === '' ? 'checklist' : route;
  console.log('~ type', type);

  useEffect(() => {
    if (navigator.onLine) {
      const callBack = (media) => setData(media)
      getMediaOnRealTime(userId, callBack)
    } else {
      if ('indexedDB' in window) {
        idb.getAll('Media').then((res) => {
          const sortedData = res.sort((a, b) => {
            return new Date(b.timeCreated) - new Date(a.timeCreated);
          })
          setData(sortedData)
        })
      }
    }
    return () => {
      window.unsubscribeSnapshot && window.unsubscribeSnapshot();
    };
  }, [userId])

  const handleChange = (x, newValue) => {
    history.push(`/${newValue === 'checklist' ? '' : newValue}`)
    setSelecting(false)
  };

  const clear = () => {
    setCheckedElements([]);
    setSelecting(false)
    setIsSending(false)
  }

  const handleSending = () => {
    if (!selecting) setSelecting(true)
    setIsSending(true);
  }

  const handleOpenSnackBar = Transition => () => {
    setTransition(() => Transition);
    setOpenSnackBar(true);
  };

  const component = {
    galerie: (
      <Galery
        userId={userId}
        userEmail={userEmail}
        type={type}
        checkedElements={checkedElements}
        setCheckedElements={setCheckedElements}
        data={data}
        isSending={isSending}
        setIsSending={setIsSending}
        selecting={selecting}
        setSelecting={setSelecting}
        handleSending={handleSending}
      />
    ),
    photo: (
      <Camera
        userId={userId}
        type={type}
        latestCapture={data[0] || {}}
      />
    ),
    video: (
      <Camera
        userId={userId}
        type={type}
        latestCapture={data[0] || {}}
      />
    ),
    checklist: (
      <Checklist
        userId={userId}
        type={type}
      />
    ),
  }

  const handleSelecting = () => {
    if (data.length > checkedElements.length) {
      setCheckedElements(data)
    }
    else setCheckedElements([])
  };

  const allSelected = data.length === checkedElements.length;

  const disabled = checkedElements.length === 0 || isSending;

  return (
    <div className={styles.mediaContainer}>
      <Header
        checkedElements={checkedElements}
        selecting={selecting}
        setSelecting={setSelecting}
        type={type}
        clear={clear}
      />
      <div className={styles.media} style={{ top: route ==="galerie" ? "87px": "53px"}}>
        {component[type]}
      </div>
      <BottomNavigation value={type} onChange={handleChange} className={classes.menu} showLabels>

        <Link to="/notice">
          <BottomNavigationAction
            classes={{
              root: classes.menuItem,
              selected: classes.selected,
            }}

            style={{ 
              position: 'fixed', 
              transform: 'translateX(-50%)', 
              textDecoration: 'none'  // Optional: removes the underline
            }}
  
            label="Politique de confidentialité"
            value="privacyNotice"
            icon={<div className={styles.dot}>.</div>}
          />
            Politique de <br />confidentialité
        </Link>

        <BottomNavigationAction
          classes={{
            root: classes.menuItem,
            selected: classes.selected,
          }}
          label="Galerie"
          value="galerie"
          icon={<div className={styles.dot}>.</div>}
        />
        <BottomNavigationAction
          classes={{
            root: classes.menuItem,
            selected: classes.selected,
          }}
          label="Photo"
          value="photo"
          icon={<div className={styles.dot}>.</div>}
        />
        <BottomNavigationAction
          classes={{
            root: classes.menuItem,
            selected: classes.selected,
          }}
          label="Vidéo"
          value="video"
          icon={<div className={styles.dot}>.</div>}
        />
        <BottomNavigationAction
          classes={{
            root: classes.menuItem,
            selected: classes.selected,
          }}
          label="Checklist"
          value="checklist"
          icon={<div className={styles.dot}>.</div>}
        />
      </BottomNavigation>


      {selecting && !isSending && (
        <BottomNavigation value={type} className={classes.subMenu}>
          <BottomNavigationAction
            showLabel
            className={classes.subMenuItem}
            label="Envoyer"
            value="envoyer"
            disabled={disabled}
            icon={<img src={disabled ? 'disabledSend.svg': 'send.svg'} className={`${styles.subMenuIcon} send`} alt="" />}
            onClick={handleSending}
          />
          <BottomNavigationAction
            showLabel
            className={classes.subMenuItem}
            label="Supprimer"
            value="supprimer"
            disabled={disabled}
            icon={<img src={disabled ? 'disabledTrash.svg': 'trash.svg'} className={`${styles.subMenuIcon} delete`} alt="" />}
            onClick={handleOpenSnackBar(TransitionUp)}
          />
          <BottomNavigationAction
            showLabel
            className={classes.subMenuItem}
            label={allSelected ? "Desélect.tout" : "Sélect.tout"}
            value="selection"
            icon={<img src={allSelected ? 'uncheck.svg' : 'check.svg'} className={styles.subMenuIcon} alt="" />}
            onClick={handleSelecting}
          />
        </BottomNavigation>
      )}
      <DeleteSnackBar
        open={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        transition={transition}
        data={data}
        checkedElements={checkedElements}
        setCheckedElements={setCheckedElements}
      />
    </div>)
}