import React from 'react';
import styles from './toDo.module.css';

function BlockItems({ItemContent}) {
  return (
    <div className={styles.ElementTitle}>
      <div className={styles.itemTitle}>{ItemContent.title}</div>  
      <div className={styles.checkboxContent}>
        <div className={styles.checkboxElement}>
          <span>F</span>
          <input
            type="checkbox"
            name="todo"
            value={true}
            checked={ItemContent.todo === true}
            disabled
          />
        </div>
      </div>
    </div>
  )
}

function BlockTitle({title}) {
  return (
    <div className={styles.blockTitleContent}>
      <div className={styles.blockTitle}>
        <img src={`${title}.svg`} alt="" />
        <h1>{title}</h1>
      </div>
      <div className={styles.tbody}>
        <span>À faire</span>
      </div>
    </div>
  )
}

const ToDo = ({todoList}) => {
  const displayBlockItems = (type) => {
    const ItemsOfBlock = todoList.filter(elm => elm.type === type)
    return ItemsOfBlock.map(item => <BlockItems ItemContent={item} />)
  } 

  const displayBlock = () => {
    const blockTypes = [...new Set(todoList.map((ele) => ele.type))]
    return blockTypes.map(type => (
      <div className={styles.blockTypesSection}>
        <BlockTitle title={type} />
        <div className={styles.blockTypesContent}>
          {displayBlockItems(type)}
        </div>
      </div>
    ))
  }

  return !!todoList.length && (
      <div className={styles.todoSection}>
      <h1>Checklist</h1>
      <div className={styles.todoList}>
        {displayBlock()}
      </div>
    </div>)
    
};

export default ToDo;