import React, { useState, Fragment } from "react";
import styles from './galery.module.css';

const Image = ({
  alt,
  thumb,
  src,
  ...rest
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Fragment>
      <img
        className={`${styles.mediaContent} ${styles.thumb}`}
        alt={alt}
        src={thumb || src}
        style={{ visibility: isLoaded ? "hidden" : "visible" }}
        data-id={rest.id || null}
      />
      <img
        onLoad={() => {
          setIsLoaded(true);
        }}
        className={`${styles.mediaContent} ${styles.full}`}
        style={{ opacity: isLoaded ? 1 : 0 }}
        alt={alt}
        src={src}
        data-id={rest.id || null}
      />
    </Fragment>
  );
};
export default Image;