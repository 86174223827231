import firebase from 'firebase';
import createThumbnail from './createThumbnail';

const db = firebase.firestore();

export default async (isTokBox, file, contentType, userId, setAlert = () => false) => {
  return new Promise(async (resolve, reject) => {
    const now = Date.now();
    const name = `Téléchargement de la vidéo`;
    const path = `galeries/${userId}/${now}`;

    let blob = isTokBox ? await fetch(file.url).then(r => r.blob()) : file;
    const storageRef = firebase.storage().ref(`${path}/${Date.now()}`);

    const uploadTask = storageRef.put(blob, { contentType });

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        setAlert({
          type: 'progress',
          title: name,
          text: 'Le téléchargement de votre vidéo est en cours. Veuillez rester sur la page jusqu\'à ce qu’il soit complété',
          percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          open: true,
        });
      }, (error) => {
        console.error('error', error);
        setAlert({
          type: 'error',
          title: name,
          text: 'Votre téléchargement a échoué. Merci d’essayer à nouveau après avoir vérifié votre connexion réseau',
          open: true,
        });
      }, async () => {
        const metadata = await uploadTask.snapshot.ref.getMetadata();
        const url = await uploadTask.snapshot.ref.getDownloadURL();

        const createdThumbnail = await createThumbnail(path, contentType, url);

        const data = {
          url,
          timeCreated: metadata.timeCreated,
          contentType: metadata.contentType,
          name: metadata.name,
          size: metadata.size,
          userId,
          thumbnailUrl: createdThumbnail?.data && !createdThumbnail.data?.error ? createdThumbnail.data.url : '',
        }

        db.collection('medias').add(data)
        .then(() => resolve('done'))
        .catch(function (error) {
          reject(error)
          console.error("Error adding document: ", error);
        });

        setAlert({
          type: 'success',
          text: 'Votre téléchargement est terminé. Vous pouvez désormais fermer cette page.',
          title: metadata.name,
          open: true,
        });
      }
    );
  })
}